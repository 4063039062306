@media screen and (max-width: 1024px) {
  .ig-template-container {
    width:16.875rem;
    height:16.875rem;
    margin-top: 10vh;
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #CBCBCB;
  }

  .ig-template-container .dropZone {
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid #CBCBCB;
    border-bottom: 1px solid #CBCBCB;
    max-height: 50%;
    max-width: 50%;
  }

  .ig-template-container .logo {
    max-height: 50%;
  }
  
  .ig-template-container .logo .logo-image {
    max-height: 100%;
  }
}



@media screen and (min-width: 1025px) {
.ig-template-container {
  width:33.75rem;
  height:33.75rem;
  margin-top: 10vh;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #CBCBCB;
}

.ig-template-container .dropZone {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid #CBCBCB;
  border-bottom: 1px solid #CBCBCB;
  max-height: 50%;
  width: 50%;
}

.ig-template.disclaimer-text {
  font-style: italic;
  margin: 2rem 0;
  min-width: 33.75rem;
}

.ig-template.disclaimer-text .text {
  color: #000;
  text-align: left;
  margin-top: 1rem;
  font-size: 1rem;
  line-height: 1.2;
}

.ig-template-container .logo {
  max-height: 50%;
}

.ig-template-container .logo .logo-image {
  max-height: 100%;
}
}

/* Large screen sizes */
@media screen and (min-width: 2600px) {
  .ig-template-container {
    width: 50.625rem;
    height: 50.625rem;
   }

   .ig-template.disclaimer-text {
    min-width: 50.625;
  }

  .ig-template.disclaimer-text .text {
   font-size: 1.4rem;
   line-height: 2.3rem;
  }
}
  
  @media screen and (min-width: 3300px) {
   .ig-template-container {
    width: 67.5rem;
    height: 67.5rem;
   }

   .ig-template.disclaimer-text {
    min-width: 67.5rem;
  }

  .ig-template.disclaimer-text .text {
   font-size: 1.6rem;
   line-height: 2.5rem;
  }
  }