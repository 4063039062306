/* Mobile devices & tablets */
@media screen and (max-width: 1024px) {
  .image-cropper .button {
    background: #F2617A;
    color: #fff;
    font-size: 1rem;
    font-family: Inter, sans-serif;
    font-weight: 700;
    border: 0;
    padding: 0.5rem;
    margin-top: 0.8rem;
  }

  .instruction {
    max-width: 80vw;
    text-align: center;
  }

  .instruction-text {
    font-size: 0.875rem;
    line-height: 1.4rem;
  }
}

@media screen and (min-width: 1025px) {
  .image-cropper .button {
    background: #F2617A;
    color: #fff;
    font-size: 1rem;
    font-family: Inter, sans-serif;
    font-weight: 700;
    border: 0;
    padding: 0.5rem;
    margin-top: 0.8rem;
    font-size: 1.5rem;
    width: 8vw;
  }

  .instruction {
margin-top: 0.8rem;
  }
}

.cropper {
  margin-top: 3vh;
}


.button:hover {
  background:hsl(350, 48.5%, 50.1%);
}