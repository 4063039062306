.canvas-container {
  display: none;
}

/* Mobile devices & tablets */
@media screen and (max-width: 900px) {
  .final-image {
    margin-top: 5vh;
    width: 90vw;
  }

  .disclaimer-text {
    font-style: italic;
    width: 90vw;
    margin-bottom: 2rem ;
  }
  
  .disclaimer-text .text {
    color: black;
    text-align: left;
    margin-top: 1rem;
    font-size: 0.5rem;
    line-height: 1.2;
  }
}

@media screen and (min-width: 901px) {
  .final-image {
    margin-top: 5vh;
    width: 454px;
    height: 256px
  }

  .disclaimer-text {
    font-style: italic;
    width: 454px;
    margin-bottom: 2rem ;
  }
  
  .disclaimer-text .text {
    color: black;
    text-align: left;
    margin-top: 1rem;
    font-size: 0.5rem;
    line-height: 1.2;
  }
}


@media screen and (min-width: 1415px) {
  .instagram.final-image {
    margin-top: 10vh;
    width:33.75rem;
    height:33.75rem;
  }

  .main.final-image {
  margin-top: 10vh;
  height: 512px;
  width: 908px;
}


.disclaimer-text.main {
  font-style: italic;
  min-width: 908px;
}

.disclaimer-text.instagram {
  font-style: italic;
  min-width: 33.75rem;
}

.disclaimer-text .text {
  color: black;
  text-align: left;
  margin-top: 30px;
  font-size: 1rem;
}
}

@media screen and (min-width: 1415px) {
  .instagram.final-image {
    margin-top: 10vh;
    width:33.75rem;
    height:33.75rem;
  }

  .main.final-image {
  margin-top: 10vh;
  height: 512px;
  width: 908px;
}


.disclaimer-text {
  font-style: italic;
  min-width: 908px;
}

.disclaimer-text .text {
  color: black;
  text-align: left;
  margin-top: 30px;
}
}

 /* Large screen sizes */
 @media screen and (min-width: 2600px) {
  .final-image.main {
    width: 1362px;
    height: 768px;
  }

  .final-image.instagram {
    width: 50.625rem;
    height: 50.625rem;
  }

  .disclaimer-text.main {
    min-width: 1362px;
  }

  .disclaimer-text.instagram {
    min-width: 50.625rem;
  }

  .template.disclaimer-text .text {
    font-size: 1.4rem;
    line-height: 2.3rem;
   }
}
  
  @media screen and (min-width: 3300px) {
    .final-image.main {
      width: 1816px;
      height: 1024px;
    }

    .final-image.instagram {
      width: 67.5rem;
      height: 67.5rem;
    }

    .disclaimer-text.main {
      min-width: 1816px;
    }

    .disclaimer-text.instagram {
      min-width: 67.5rem;
    }

    .disclaimer-text .text {
      font-size: 1.6rem;
      line-height: 2.5rem;
    }
  }