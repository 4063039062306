@import url("https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap");

/* Mobile devices & tablets */
@media screen and (max-width: 1024px) {
  .header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #ffffff;
    padding: 10px;
    top: 0px;
    left: 0px;
  }

  .header .logo {
    margin: 2vh 8vw;
    width: 40vw;
  }

  .header .link-container {
    display: none;
  }
}

/* Desktops and landscape tablets*/
@media screen and (min-width: 1025px) {
  .header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background: #ffffff;
    padding: 1vh 0;
    top: 0px;
    left: 0px;
    width: 100%;
    min-height: 9vh;
  }

  .header .logo {
    margin-top: 2vh;
    margin-left: 8vw;
    width: 9vw;
    margin-bottom: 18px;
  }
  .link-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8vw;
  }
  .header .link {
    margin-top: 2vh;
    height: 2vh;
    margin-bottom: 2vh;
    margin-right: 0.5rem;
    text-align: left;
    font: normal normal bold 16px/24px Inter, sans-serif;
    color: #000;
    text-decoration: none;
  }

  .header .link-container .chevron::before {
    border-style: solid;
    border-width: 0.15rem 0.15rem 0 0;
    content: "";
    display: inline-block;
    height: 0.45rem;
    left: 0.15rem;
    position: relative;
    top: 0.15rem;
    transform: rotate(135deg);
    vertical-align: center;
    width: 0.45rem;
    color: #f2617a;
  }
}

#campaigns {
  appearance: none;
  padding-right: 1.5rem;
  padding-left: 0.3rem;
  background-image: url("../../assets/icons/down-chevron.png");
  background-size: contain;
  background-position: right 0.1rem center;
  background-repeat: no-repeat;
  border: none;
  border-left: 1px solid grey;
}

/* Large screen sizes */
@media screen and (min-width: 2600px) {
  .header .link {
    margin-top: 1.3rem;
    font-family: Inter, sans-serif;
    font-weight: bold;
    font-size: 1.5rem;
  }

  .header .link-container .chevron::before {
    border-width: 0.19rem 0.19rem 0 0;
    height: 0.875rem;
    width: 0.875rem;
  }
}

@media screen and (min-width: 3300px) {
  .header .link {
    margin-top: 3.5rem;
    font-family: Inter, sans-serif;
    font-weight: bold;
    font-size: 2rem;
  }

  .header .link-container .chevron::before {
    border-width: 0.3rem 0.3rem 0 0;
    height: 1rem;
    width: 1rem;
  }
}
