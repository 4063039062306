/* Mobile devices & tablets */
@media screen and (max-width: 900px) {
  .template-container {
    display: flex;
    justify-content: center;
    max-width: 303px;
    max-height: 171px;
    margin: 5vh 0 0.5rem;
  }
  
  .template-container .dropZone {
    background-color: #fff;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    border: 1px solid #CBCBCB;
  }
  
  .disclaimer-text {
    font-style: italic;
    max-width: 303px;
    margin-bottom: 2rem ;
  }
  
  .disclaimer-text .text {
    color: black;
    text-align: left;
    margin-top: 1rem;
    font-size: 0.5rem;
    line-height: 1.2;
  }
}


@media screen and (min-width: 901px) {
  .template-container {
    display: flex;
    justify-content: center;
    width: 454px;
    height: 256px;
    margin: 10vh 0 1.5rem;
    background: #FFFFFF;
  }

  .template-container .dropZone {
    background-color: #ffffff;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    border: 1px solid #CBCBCB;
  }
  
  .template.disclaimer-text {
    font-style: italic;
    min-width: 454px;
    max-width: 454px;
  }
  
  .template.disclaimer-text .text {
    color: black;
    text-align: left;
    margin-top: 15px;
    font-size: 1rem;
  }
}

@media screen and (min-width: 1415px) {
.template-container {
  display: flex;
  justify-content: center;
  width: 908px;
  height: 512px;
  margin: 10vh 0 1.5rem;
  background: #FFFFFF;
}

.template-container .dropZone {
  background-color: #ffffff;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  border: 1px solid #CBCBCB;
}

.template.disclaimer-text {
  font-style: italic;
  min-width: 908px;
}

.template.disclaimer-text .text {
  color: black;
  text-align: left;
  margin-top: 15px
}
}

 /* Large screen sizes */
 @media screen and (min-width: 2600px) {
  .template-container {
    width: 1362px;
    height: 768px;
   }

   .template.disclaimer-text {
    min-width: 1362px;
  }

  .template.disclaimer-text .text {
   font-size: 1.4rem;
   line-height: 2.3rem;
  }
}
  
  @media screen and (min-width: 3300px) {
   .template-container {
    width: 1816px;
    height: 1024px;
   }

   .template.disclaimer-text {
    min-width: 1816px;
  }

  .template.disclaimer-text .text {
   font-size: 1.6rem;
   line-height: 2.5rem;
  }
  }