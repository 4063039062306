/* Mobile devices & tablets */
@media screen and (max-width: 1024px) {
  .dropBox .drop-icon img {
    max-width: 30px;
  }

  .dropBox .button {
    background: #F2617A;
    color: #fff;
    font-size: 0.5rem;
    font-family: Inter, sans-serif;
    font-weight: 700;
    border: 0;
    padding: 0.5rem;
    margin-top: 0.5rem;
  }

  .dropBox .dropBoxText {
    text-align: center;
    font: 0.5rem Inter, sans-serif;
    letter-spacing: 0px;
    color: #000;
  }

  .dropZone .dropBox {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center; 
    height: 80%;
    width: 80%;
    padding: 2rem;
    border: 1px dashed #CBCBCB;
  }
}

@media screen and (min-width: 1025px) {
  .dropBox .drop-icon img {
    max-width: 43px;
    height: 39px
  }
  
  .dropBox .button {
    background: #F2617A;
    color: #fff;
    font-size: 1rem;
    font-family: Inter, sans-serif;
    font-weight: 700;
    border: 0;
    padding: 0.5rem;
    margin-top: 0.5rem;
    width: 8vw;
  }
  
  .dropBox .dropBoxText {
    text-align: center;
    font: 1rem Inter, sans-serif;
    letter-spacing: 0px;
    color: #000;
    max-width: 10vw;
  }
  
  .dropZone .dropBox {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center; 
    height: 75%;
    width: 75%;
    padding: 2rem;
    border: 1px dashed #CBCBCB;
    background: var(--wave) 0% 0% no-repeat padding-box;
  }
}

@media screen and (min-width: 1415px) {
.dropBox .drop-icon img {
  max-width: 43px;
  height: 39px
}

.dropBox .button {
  background: #F2617A;
  color: #fff;
  font-size: 1.5rem;
  font-family: Inter, sans-serif;
  font-weight: 700;
  border: 0;
  padding: 0.5rem;
  margin-top: 0.5rem;
  width: 8vw;
}

.dropBox .dropBoxText {
  text-align: center;
  font: 1.2rem Inter, sans-serif;
  letter-spacing: 0px;
  color: #000;
  max-width: 10vw;
}

.dropZone .dropBox {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center; 
  height: 75%;
  width: 75%;
  padding: 2rem;
  border: 1px dashed #CBCBCB;
  background: var(--wave) 0% 0% no-repeat padding-box;
}

}

.dropBox .button:hover {
  background:hsl(350, 48.5%, 50.1%);
}
