/* Mobile devices & tablets */
@media screen and (max-width: 1024px) {
  .card {
    display:flex;
    max-width: 90vw;
    /* min-height: 25vh; */
    flex: 1;
    margin: 1rem 0;
    } 
}

/* Desktops and landscape tablets */
@media screen and (min-width: 1025px) {
.card {
  display:flex;
  min-width: 16.875rem;
  flex: 1;
  margin: 1rem 2rem 1rem 0;
  }
}
  
.card:hover {
  border: 1px solid #F2617A;
}

.card .placeholder-container  {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  flex: 1;
  border: 1px solid #CBCBCB;
}

.card .placeholder-container .placeholder {
  width: 127px;
  height: 127px;
}

.card .card-template {
  display: flex;
  flex-direction: column;
  flex: 1;
  background: #003d4f;
}

.card .card-template .logo-container,
.card .card-template .background-container {
  flex: 1;
  height: 50%;
}

.card .card-template .logo,
.card .card-template .background {
  width: 100%;
  height: 100%;
}

.card .card-template .logo {
  /* border: 1px solid #CBCBCB; */
  border-left: none;
  border-bottom: none;
}

@media screen and (min-width: 1700px) {
  .card {
    display:flex;
    min-width: 25.313rem;
    flex: 1;
    margin: 1rem 2rem 1rem 0;
    }
}
@media screen and (min-width: 2600px) {
  .card {
    display:flex;
    min-width: 33.75rem;
    flex: 1;
    margin: 1rem 2rem 1rem 0;
    }
}
@media screen and (min-width: 3300px) {
  .card {
    display:flex;
    min-width: 42.188rem;
    flex: 1;
    margin: 1rem 2rem 1rem 0;
    }
}