@import url("https://fonts.googleapis.com/css2?family=Bitter:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700&display=swap");

/* Mobile devices & tablets */
@media screen and (max-width: 1024px) {
  .panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #003d4f;
    width: 100vw;
    padding-bottom: 5vh;
  }

  .aligned {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .panel .text {
    font: normal normal bold 1.6rem Bitter, serif;
    color: #ffffff;
    padding-top: 5vh;
    flex-wrap: wrap;
    text-align: left;
    margin-left: 8vw;
  }

  .panel .social-icons-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 5vh 0 5vh 8vw;
    max-width: 50vw;
  }

  .panel img {
    max-width: 2.5rem;
    max-height: 2.5rem;
  }

  .panel .download-info {
    max-width: 75vw;
    margin-left: 8vw;
  }

  .fb-alt-text {
    margin-left: 1rem;
  }

  .panel .download-info p {
    color: #fff;
    text-align: left;
    margin: 0 0 3vh 0;
  }

  .panel .download-info h3 {
    color: #fff;
    text-align: left;
    margin-bottom: 2vh;
  }

  .panel .download-info a {
    color: #f2617a;
    text-decoration: none;
    font-family: "Inter", sans-serif;
    font-weight: 700;
  }

  .panel a {
    text-decoration: none;
  }

  .panel .btn {
    background: #f2617a;
    color: #fff;
    text-align: center;
    font-family: "Inter", sans-serif;
    font-weight: 700;
    margin-top: 3vh;
    margin-left: 8vw;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
  }

  .back-nav-container {
    display: flex;
    align-items: center;
    line-height: 1.2rem;
    margin-bottom: 2vh;
  }

  .back-nav {
    font-family: "Inter", sans-serif;
    font-size: 1rem;
    text-decoration: none;
    color: #fff;
    margin-left: 0.75rem;
    vertical-align: top;
  }

  .reverse-chevron::before {
    border-style: solid;
    border-width: 0.13rem 0.13rem 0 0;
    content: "";
    display: inline-block;
    height: 0.45rem;
    left: 0.15rem;
    position: relative;
    top: 0.25rem;
    transform: rotate(225deg);
    vertical-align: top;
    width: 0.45rem;
    color: #f2617a;
  }

  .back-nav-clear {
    margin-top: 2vh;
  }

  .captions {
    font-style: italic;
  }
}

/* Desktops and landscape tablets */
@media screen and (min-width: 1025px) {
  .panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #003d4f;
    min-height: 91vh;
    flex: 1;
  }

  .panel .text {
    font: normal normal bold 2.2rem Bitter, serif;
    color: #ffffff;
    margin-top: 5vh;
    flex-wrap: wrap;
    min-width: 20vw;
  }

  .aligned {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .downloaded {
    margin-top: 2vh;
    max-width: 17vw;
  }

  .panel .downloaded .social-icons-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2vh 0;
    max-width: 11vw;
  }

  .panel img {
    max-width: 2.5rem;
    max-height: 2.5rem;
  }

  .panel .download-info {
    min-width: 20vw;
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
  }

  .captions {
    min-width: 23vw;
    font-style: italic;
  }

  .panel .download-info p {
    color: #fff;
    text-align: left;
    margin: 0 0 2vh 0;
  }
  .panel .download-info a {
    color: #f2617a;
    text-decoration: none;
    font-family: "Inter", sans-serif;
    font-weight: 700;
  }

  .ig-alt-text {
    margin-top: -3vh;
    margin-bottom: 3vh;
  }

  .fb-alt-text {
    margin-left: 1rem;
  }

  .close-up {
    margin-bottom: 0 !important;
  }

  .panel .download-info h3 {
    color: #fff;
    text-align: left;
    margin-bottom: 2vh;
  }

  .panel a {
    text-decoration: none;
  }

  .panel .btn {
    background: #f2617a;
    color: #fff;
    text-align: center;
    font-family: "Inter", sans-serif;
    font-weight: 700;
    margin-top: 6vh;
    width: 10vw;
    padding: 0.5rem 1rem;
    font-size: 1rem;
  }

  .back-nav-container {
    display: flex;
    align-items: center;
    line-height: 1.2rem;
    margin-bottom: 2vh;
  }

  .back-nav {
    font-family: "Inter", sans-serif;
    font-size: 1rem;
    text-decoration: none;
    color: #fff;
    margin-left: 0.75rem;
    margin-top: 2vh;
  }

  .reverse-chevron::before {
    border-style: solid;
    border-width: 0.13rem 0.13rem 0 0;
    content: "";
    display: inline-block;
    height: 0.45rem;
    left: 0.15rem;
    position: relative;
    top: 0.15rem;
    transform: rotate(225deg);
    vertical-align: center;
    width: 0.45rem;
    color: #f2617a;
  }

  .back-nav-clear {
    margin-top: 6vh;
  }
}

/* Large screen sizes */
@media screen and (min-width: 2600px) {
  .panel .text {
    font-family: Bitter, serif;
    font-size: 3rem;
    color: #ffffff;
    margin-top: 5vh;
    flex-wrap: wrap;
    min-width: 20vw;
  }

  .panel .downloaded .social-icons-container {
    max-width: 9vw;
  }
}

@media screen and (min-width: 3300px) {
  .panel .text {
    font-size: 4rem;
  }

  .back-nav {
    font-size: 2rem;
    margin-top: 1vh;
  }

  .reverse-chevron::before {
    border-width: 0.3rem 0.3rem 0 0;
    height: 1rem;
    width: 1rem;
  }

  .panel .download-info {
    font-size: 2rem;
    line-height: 2.5rem;
  }

  .panel .downloaded .social-icons-container {
    max-width: 8vw;
  }
}
