/* Mobile devices & tablets */
@media screen and (max-width: 1024px) {
  .template-container .banner {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  
  .template-container .banner .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    max-height: 50%;
  }


  .logo-image {
      width: 100%;
      height: 100%;
  }

  .image{
    min-height: 50%;
  }

}

@media screen and (min-width: 1025px) {
   .banner {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  
  .banner .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 50%
  }


  .banner .image {
    flex: 1;
  }

  .logo-image{
  width: 100%;
  height: 100%;
  }
}