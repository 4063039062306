/* Desktops and landscape tablets*/
@media screen and (min-width: 1025px) {
  .footer {
    display: none;
  }
}

footer {
  position: absolute;
  bottom: 0;
}

.footer {

  background: #fff;
  height: 9vh;
  width: 100vw;
}

.footer .link-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.footer .link {
  margin-top: 24px;
  height: 24px;
  margin-bottom: 18px;
  text-align: left;
  font: bold 1rem Inter, sans-serif;

  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  text-decoration: none;
}

.chevron::before {
	border-style: solid;
	border-width: 0.15rem 0.15rem 0 0;
	content: '';
	display: inline-block;
	height: 0.45rem;
	left: 0.15rem;
	position: relative;
	top: 0.15rem;
	transform: rotate(45deg);
	vertical-align: center;
	width: 0.45rem;
  color: #F2617A;
}