/* Mobile devices & tablets */
@media screen and (max-width: 1024px) {
    .ig-card {
      display:flex;
      flex-direction: column;
      max-width: 16.875rem;
      max-height: 16.875rem;
      flex: 1;
      margin: 1rem 0;
      }
}

/* Desktops and landscape tablets */
@media screen and (min-width: 1025px) {
.ig-card {
  display:flex;
  flex-direction: column;
  max-width: 16.875rem;
  max-height: 16.875rem;
  flex: 1;
  margin: 1rem 2rem 1rem 0;
  }
}

@media screen and (min-width: 2600px) {
.ig-card {
  display:flex;
  flex-direction: column;
  max-width: 27rem;
  max-height: 27rem;
  flex: 1;
  margin: 1rem 2rem 1rem 0;
  }
}
@media screen and (min-width: 3300px) {
.ig-card {
  display:flex;
  flex-direction: column;
  max-width: 42.188rem;
  max-height: 42.188rem;
  flex: 1;
  margin: 1rem 2rem 1rem 0;
  }
}

.ig-card .card-template {
  display: flex;
  min-height: 50%;
  width: 100%;
}

.ig-card .background-container,
.ig-card .placeholder-container {
  min-height: 100%;
  width: 50%;
  flex: 1;
}

.ig-card .placeholder-container {
 display: flex;
 border: 1px solid #CBCBCB;
 background: #fff;
 align-items: center;
 justify-content: center;
}

.ig-card .background-container .background {
height: 100%;
width: 100%;
}

.ig-card .placeholder-container .placeholder {
  width: 80%;
  height: 80%;
}

.ig-card .logo-container .logo {
  height: 100%;
  width: 100%;
  /* border: 1px solid #CBCBCB; */
  border-top: none;
}

.ig-card .logo-container {
  min-height: 50%;
  min-width: 100%;
  flex: 1;
}
  
.ig-card:hover {
  border: 1px solid #F2617A;
}
