@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700&display=swap');
/* Mobile devices & tablets */
@media screen and (max-width: 1024px) {
  .selector .title h1 {
    font: bold 1.6rem Inter, sans-serif;
    text-align: center;
    margin: 3vh 0;
  }

  .selector h2 { 
    margin: 2vh 0;
  }

  .card-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100vw;
  }
}


@media screen and (min-width: 1025px) {
  .selector .title h1 {
    font: bold 1.6rem Inter, sans-serif;
    text-align: left;
    margin-top: 10vh;
    margin-left: 8vw;
  }

  .selector h2 {
    font: bold 1.2rem Inter, sans-serif;
    text-align: left;
    margin-top: 2vh;
    margin-left: 8vw;
  }

  .selector {
    width: 100%;
    height: 100%;
  }

  .card-container {
    display: grid;
    grid-template-columns: 28rem 28rem;
    padding: 3vh 2vw 2vh 0;
    margin-left: 8vw;
  }

  .ig-1 {
      grid-template-columns: 18rem 18rem;
  } 
}

/* Large screen sizes */
@media screen and (min-width: 2600px) {  
.selector .title h1 {
  font-family:  Inter, sans-serif;
  font-size: 2.5rem;
}

.selector h2 {
  font: bold 1.6rem Inter, sans-serif;
}

.card-container {
  display: grid;
  grid-template-columns: 39rem 39rem;
}

.ig-1 {
  grid-template-columns: 27rem 27rem;
} 
}

@media screen and (min-width: 3300px) {
  .selector .title h1 {
    font-family:  Inter, sans-serif;
    font-size: 3.5rem;
  }
  
  .selector h2 {
    font: bold 2rem Inter, sans-serif;
  }

  .card-container {
    display: grid;
    grid-template-columns: 50rem 50rem;
  }

  .ig-1 {
    grid-template-columns: 35rem 35rem;
  } 
}
