@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700&display=swap');

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  position: relative;
  line-height: 1.6rem;
  width: 100%;
  height: 100%;
  background: #edf1f3;
}


/* Mobile devices & tablets */
@media screen and (max-width: 1024px) {
  main {
    display: flex;
    flex-direction: column;
    min-height: 80vh;
  }

  .clear {
    clear: both;
    height: 10vh;
  }

  .main-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  .logout-btn {
    background:#F2617A;
    color: #fff;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    padding:0.5rem 0.5rem;
    font-size: 0.5rem;
    height: 4vh;
    position: absolute;
    top: 3vh;
    right: 2vw;
    border: none;
  }
}

@media screen and (min-width: 1025px) {
  main {
    display: flex;
  }

.main-area {
  display: flex;
  flex-direction: column;
  flex: 2;
  max-width: 66%;
  align-items: center;
  position: relative
}

 .logout-btn {
  background:#F2617A;
  color: #fff;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  padding:0.5rem 1rem;
  font-size: 0.875rem;
  height: 5vh;
  position: absolute;
  bottom: 3vh;
  right: 2vw;
  border: none;
}
}


.logout-btn:hover {
  background:hsl(350, 48.5%, 50.1%);
}

